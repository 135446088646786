<template>
  <div class="about">
    <h1>This is an about page</h1>

    <aside class="menu">
      <p class="menu-label">
        General
      </p>
      <ul class="menu-list">
        <li><router-link to='/about/Overview'> Dashboard</router-link></li>
    
      </ul>
    </aside>

    <router-view/>
  </div>
</template>


<script>
export default {
    name: 'About',
}
</script>
